import React from 'react';
import '../scss/Footer.scss';


class FooterController extends React.Component {
  render(){
    return(
      <div className='footer'>
        <p>🤖 Thanks for stopping by! </p>
      </div>
    )
  }
}

export default FooterController;